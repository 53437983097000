import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Backdrop, Fade, Typography } from "@material-ui/core";
import Slider from "@mui/material/Slider";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../redux/data/dataActions";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Web3 from "web3";

const CTA = styled.button`
  background-color: #e6754e;
  color: #fff;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  border-radius: 20px;
  cursor: pointer;
  font-size: calc(1rem + 0.5vw);
  font-weight: 800;
  display: flex;
  align-items: center;
  transition: transform 0.2s;

  img {
    width: 1.5rem;
  }
  @media only screen and (max-width: 48em) {
    padding: 0.2rem 1rem;
  }
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
`;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    justifySelf: "center",
    textAlign: "center",
    fontFamily: "Integral Bold",
  },
  paper: {
    backgroundColor: "#FAFAFA",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "40%",
    //maxHeight: '100%',
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Integral Bold",
  },
  modalTitle: {
    color: "#000000",
    minHeight: "100px",
    fontFamily: "Integral Bold",
  },
  feedbackText: {
    minHeight: "100px",
    color: "#000000",
    fontFamily: "Integral Bold",
  },
  mintBtn1: {
    padding: "0",
    border: "1.5px solid #fff",
    backgroundColor: "#D8532E",
    margin: "0rem 0.8rem",
    "&:hover": {
      backgroundColor: "#D8532E",
      transition: ".5s",
    },
  },
  counter: {
    padding: "12px 21px",
    color: "#fff",
    fontFamily: "Integral Bold",
  },
}));

const marks = [];
for (let i = 1; i <= 10; i++) {
  marks.push({
    value: i,
    label: i.toString(),
  });
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AnimatedModal() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [snackState, setSnackState] = React.useState({
    state: false,
    severity: "",
    message: "",
  });

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  console.log("data=>", data);

  // const [feedback, setFeedback] = useState('Sample Feedback')
  const [claimingNft, setClaimingNft] = useState(false);
  const [mintAmount, setMintAmount] = useState(1);
  const [nftCost, setNftCost] = useState(0.05);
  const [modalTitle, setModalTitle] = useState("");
  const [maxMintAmount, setMaxMintAmount] = useState(0);

  // const handleClick = () => {
  //   setSnackState({
  //     state: true,
  //     severity: 'error',
  //     message: 'Something went wrong',
  //   })
  // }

  // Create handleIncrement event handler
  const handleIncrement = () => {
    if (mintAmount < maxMintAmount) {
      setMintAmount((prevCount) => prevCount + 1);
    }
  };

  //Create handleDecrement event handler
  const handleDecrement = () => {
    if (mintAmount !== 1) {
      setMintAmount((prevCount) => prevCount - 1);
    }
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      setSnackState({
        state: false,
        severity: "",
        message: "",
      });

      setMintAmount(1);
      return;
    }

    setSnackState({
      state: false,
      severity: "",
      message: "",
    });

    setMintAmount(1);
  };

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.smartContract, dispatch, blockchain.account]);

  useEffect(() => {
    if (data && data.cost) {
      setNftCost(Web3.utils.fromWei(data.cost, "ether"));
      setModalTitle("WaitList Mint");
      setMaxMintAmount(data.maxMintAmount);
      setDataLoaded(true);
    }
  }, [data]);

  const claimNFTs = (_amount) => {
    // handleClick();
    let totalGasLimit = String(285000 * _amount);

    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        from: blockchain.account,
        gasLimit: String(totalGasLimit),
        value: blockchain.web3.utils.toWei(
          (nftCost * _amount).toString(),
          "ether"
        ),
      })
      .once("error", (err) => {
        setSnackState({
          state: true,
          severity: "error",
          message: "Something went wrong",
        });
        setOpen(false);
        setClaimingNft(false);
        setMintAmount(1);
      })
      .then((receipt) => {
        setSnackState({
          state: true,
          severity: "success",
          message: "Successfully minted",
        });
        setOpen(false);
        setClaimingNft(false);
        setMintAmount(1);
      });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function valuetext(value) {
    return `${value}°C`;
  }

  const handleSilderChange = (e) => {
    const { value } = e.target;
    setMintAmount(value);
  };

  return (
    <Grid
      contianer
      alignContent="center"
      justifyContent="center"
      spacing={2}
      className={classes.root}
    >
      <button
        className="btn"
        onClick={handleOpen}
        style={{ fontFamily: "Integral Bold" }}
        disabled={!dataLoaded}
      >
        {dataLoaded ? "Mint Your NFT" : <CircularProgress />}
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Grid item className={classes.paper} xl={4} lg={4} sm={6} xs={10}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h5" className={classes.modalTitle}>
                {modalTitle || "loading..."}
              </Typography>

              <Typography variant="subtitle1" className={classes.feedbackText}>
                Up to {maxMintAmount} tokens per time
              </Typography>

              <Button variant="outlined" className={classes.mintBtn1}>
                <Typography
                  onClick={handleDecrement}
                  className={classes.counter}
                >
                  -
                </Typography>
                <Typography className={classes.counter}>
                  {mintAmount}
                </Typography>
                <Typography
                  onClick={handleIncrement}
                  className={classes.counter}
                >
                  +
                </Typography>
              </Button>

              {/* <Slider
                aria-label="Small steps"
                defaultValue={mintAmount}
                getAriaValueText={valuetext}
                step={1}
                marks={marks}
                min={1}
                max={10}
                valueLabelDisplay="auto"
                onChange={(e) => handleSilderChange(e)}
              /> */}

              <Typography variant="subtitle1" className={classes.feedbackText}>
                {nftCost} ETH for each Character + gas fees ⛽. Recommended to
                mint many at a time (maximum {maxMintAmount}) to economize gas
                fees.
              </Typography>

              <Grid container textAlign="center">
                <Grid item xs={12} sm={12}>
                  <Button
                    variant="contained"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      fontSize: "1rem",
                      padding: "0.3rem 2rem",
                      textTransform: "uppercase",
                      border: "none",
                      backgroundColor: "#D8532E",
                      borderRadius: "10rem",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#D8532E",
                        transition: ".5s",
                      },
                    }}
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      claimNFTs(mintAmount);
                    }}
                  >
                    {claimingNft ? (
                      <CircularProgress />
                    ) : (
                      `CLAIM ${mintAmount} NFT`
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      </Modal>
      <Snackbar
        open={snackState.state}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        message="Note archived"
        //action={action}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackState.severity}
          sx={{ width: "100%" }}
        >
          {snackState.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
